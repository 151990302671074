var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"card-custom",attrs:{"no-body":"","header-tag":"header","footer-tag":"footer"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("MENU.ITEM.PROMOTIONS.PROMOTIONS"))+" "),_c('i',{staticClass:"mr-2"}),_c('small',{},[_vm._v(_vm._s(_vm.$t("LABELS.LIST")))])])]),_c('div',{staticClass:"card-toolbar"},[_c('router-link',{directives:[{name:"permission",rawName:"v-permission",value:(['promotions-store']),expression:"['promotions-store']"}],staticClass:"btn btn-light-success font-weight-bolder",attrs:{"to":"add"}},[_c('i',{staticClass:"flaticon2-plus"}),_vm._v(" "+_vm._s(_vm.$t("LABELS.ADD_NEW")))])],1)]},proxy:true}])},[_c('v-card',[_c('SearchTools',{attrs:{"searchQuery":_vm.searchQuery},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event},"do-search":_vm.doSearch,"do-clear":_vm.doClear},scopedSlots:_vm._u([{key:"prepend",fn:function(){return undefined},proxy:true}])}),_c('v-data-table',{staticClass:"px-6 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"items-per-page":_vm.totalItems,"server-items-length":_vm.totalItems,"loading":_vm.isLoadingPromotion,"loading-text":"Loading... Please wait","footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: _vm.rowsPerPageItems,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right'
        }},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.getDataFromApi()}]},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.active),"small":"","dark":"","pill":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconIsActive(item.active)))])],1),_vm._v(" "+_vm._s(_vm.textIsActive(item.active)))],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.checkIfUserHasPermission(['promotions-update']))?_c('router-link',{attrs:{"to":{ name: 'edit-promotion', params: { id: item.id } }}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("LABELS.EDIT")))])])],1):[_vm._v("Няма права")]]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchQuery)+"\" found no results. ")])]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }